
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButton, modalController, IonButtons, menuController } from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, onMounted, computed } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiRimborsi from "@/services/rimborsi";

import ModalNuovoRimborso from "@/components/rimborsi_km/ModalNuovoRimborso.vue";
import RimborsoDetail from "@/components/rimborsi_km/RimborsoDetail.vue";

export default {
    name: "Rimborsi",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
    },
    setup() {
        const loading = ref(false);
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;

        const openMenu = () => {
            menuController.open("app-menu");
        };

        function setBadgeClass(rimborsata) {
            if (rimborsata == 1) {
                return "badge_success";
            }
        }

        /**
         * Get all rimborsi
         */
        const rimborsi = ref([]);
        async function loadRimborsi() {
            loading.value = true;
            try {
                const res = await apiRimborsi.getRimborsi(dipendenteID);
                if (res.status === 200 && res.data.status === 0) {
                    rimborsi.value = res.data.data;    
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei rimborsi", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta dei rimborsi", "toast_danger");
            }
            loading.value = false;
        }

        /**
         * Calculate total km for current month
         */
        const totalKmCurrentMonth = computed(() => {
            const totalKm = rimborsi.value.reduce(function(acc, current) {
                if (!current.rimborsi_km_km) {
                    return acc + 0;
                } else {
                    if (moment(current.rimborsi_km_data).isSame(new Date(), "month")) {
                        return acc + parseInt(current.rimborsi_km_km);
                    } else {
                        return acc + 0;
                    }
                }
            }, 0);

            return totalKm;
        });

        /**
         * Calculate total km cost for current month
         */
        const totalCostCurrentMonth = computed(() => {
            const totalCost = rimborsi.value.reduce(function(acc, current) {
                if (moment(current.rimborsi_km_data).isSame(new Date(), "month")) {
                    return acc + parseFloat(current.rimborsi_km_costo_viaggio);
                } else {
                    return acc + 0;
                }
            }, 0);

            return totalCost.toFixed(2);
        });

        /**
         * Open modal to create new rimborso
         */
        async function openModal() {
            const modal = await modalController.create({
                component: ModalNuovoRimborso,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    rimborsi.value = [detail.data, ...rimborsi.value];
                    openToast("Rimborso inserito correttamente", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * ! Limit rimborso customer length to 40 charactes
         */
        function setShortCustomer(rimborso) {
            if (rimborso.rimborsi_km_cliente) {
                if (rimborso.customers_company) {
                    const trimmedString =
                        rimborso.customers_company.length > 45 ? rimborso.customers_company.substring(0, 42) + "..." : rimborso.customers_company;
                    return trimmedString;
                } else {
                    const concat = `${rimborso.customers_name} ${rimborso.customers_last_name}`;
                    const trimmedString = concat.length > 45 ? concat.substring(0, 42) + "..." : concat;
                    return trimmedString;
                }
            } else if (rimborso.rimborsi_km_clienti) {
                const trimmedString =
                    rimborso.rimborsi_km_clienti.length > 45 ? rimborso.rimborsi_km_clienti.substring(0, 42) + "..." : rimborso.rimborsi_km_clienti;
                return trimmedString;
            } else {
                return "";
            }
        }

        /**
         * Passing rimborso object to detail page
         */
        async function openDetailModal(rimborso) {
            //console.log(rimborso);
            const modal = await modalController.create({
                component: RimborsoDetail,
                componentProps: {
                    data: rimborso,
                },
            });
            return modal.present();
        }

        onMounted(() => {
            loadRimborsi();
        });

        return {
            loading,
            dateFormat,
            add,
            refresh,
            openModal,
            //New field
            loadRimborsi,
            rimborsi,
            totalKmCurrentMonth,
            totalCostCurrentMonth,
            openDetailModal,
            openMenu,
            menu,
            setBadgeClass,
            setShortCustomer,
        };
    },
};
